import useCatalogueStructure from 'modules/products/useCatalogueStructure'

// TODO should be enum from backend - added on 07.01.2022 by sonatskiy

type SubscriptionCatalogueName = 'perfumes' | 'colognes' | 'beauty';
type TheShopCatalogueName =
  | 'shop'
  | 'accessories'
  | 'candlesHomeScents'
  | 'scentbirdCases'
  | 'theShopPerfumes'
  | 'theShopColognes'
  | 'theShopAllBathAndBody'
  | 'theShopAllCandlesHomeScents'
type TheEditCatalogueName =
  | 'theEdit'
  | 'allTheEdit'
  | 'accessoriesTheEdit'
  | 'candlesTheEdit'
  | 'deodorantsTheEdit'
  | 'diffusersTheEdit'
  | 'fragranceTheEdit'
  | 'homeTheEdit'
  | 'bathBodyTheEdit'
  | 'giftSetsTheEdit'
  | 'carTheEdit'
  | 'otherTheEdit'
type SaksCatalogueName =
  | 'SaksWeb'
  | 'SaksAll'
  | 'SaksPerfumes'
  | 'SaksColognes'
  | 'SaksUnisex'
type HolidayCatalogueName =
  'allHoliday'
  | 'All'
  | 'Candles'
  | 'Deodorants'
  | 'Diffusers'
  | 'Other'
  | 'Fragrances'
  | 'GiftSets'
export type GiftSetsCatalogueName =
  | 'giftSetsPerfumes'
  | 'giftSetsColognes'
export type ScentHoroscopeCatalogueName =
  | 'horoscopeAriesCartFemale'
  | 'horoscopeAriesCartMale'
  | 'horoscopeAriesQueueFemale'
  | 'horoscopeAriesQueueMale'
  | 'horoscopeTaurusCartFemale'
  | 'horoscopeTaurusCartMale'
  | 'horoscopeTaurusQueueFemale'
  | 'horoscopeTaurusQueueMale'
  | 'horoscopeGeminiCartFemale'
  | 'horoscopeGeminiCartMale'
  | 'horoscopeGeminiQueueFemale'
  | 'horoscopeGeminiQueueMale'
  | 'horoscopeCancerCartFemale'
  | 'horoscopeCancerCartMale'
  | 'horoscopeCancerQueueFemale'
  | 'horoscopeCancerQueueMale'
  | 'horoscopeLeoCartFemale'
  | 'horoscopeLeoCartMale'
  | 'horoscopeLeoQueueFemale'
  | 'horoscopeLeoQueueMale'
  | 'horoscopeVirgoCartFemale'
  | 'horoscopeVirgoCartMale'
  | 'horoscopeVirgoQueueFemale'
  | 'horoscopeVirgoQueueMale'
  | 'horoscopeLibraCartFemale'
  | 'horoscopeLibraCartMale'
  | 'horoscopeLibraQueueFemale'
  | 'horoscopeLibraQueueMale'
  | 'horoscopeScorpioCartFemale'
  | 'horoscopeScorpioCartMale'
  | 'horoscopeScorpioQueueFemale'
  | 'horoscopeScorpioQueueMale'
  | 'horoscopeSagittariusCartFemale'
  | 'horoscopeSagittariusCartMale'
  | 'horoscopeSagittariusQueueFemale'
  | 'horoscopeSagittariusQueueMale'
  | 'horoscopeCapricornCartFemale'
  | 'horoscopeCapricornCartMale'
  | 'horoscopeCapricornQueueFemale'
  | 'horoscopeCapricornQueueMale'
  | 'horoscopeAquariusCartFemale'
  | 'horoscopeAquariusCartMale'
  | 'horoscopeAquariusQueueFemale'
  | 'horoscopeAquariusQueueMale'
  | 'horoscopePiscesCartFemale'
  | 'horoscopePiscesCartMale'
  | 'horoscopePiscesQueueFemale'
  | 'horoscopePiscesQueueMale'
export type ShopTravelCatalogueName =
  | 'shopTravelSizePerfumes'
  | 'shopTravelSizeColognes'
export type ShopSampleCatalogueName =
  | 'shopSamplePerfumes'
  | 'shopSampleColognes'
export type ShopMiniBottlesCatalogueName =
  | 'shopMiniBottledPerfumes'
  | 'shopMiniBottledColognes'
export type ShopGiftsUnder50CatalogueName =
  | 'giftsUnder50'
  | 'giftsUnder50All'
  | 'giftsUnder50Fragrances'
  | 'giftsUnder50GiftSets'
  | 'giftsUnder50Candles'
  | 'giftsUnder50Diffusers'
  | 'giftsUnder50Other'

export type HolidayShopCatalogueName =
  | 'holidayShop'
  | 'holidayShopForHer'
  | 'holidayShopForHim'
  | 'allHoliday'

export type CatalogueName =
  | SubscriptionCatalogueName
  | TheShopCatalogueName
  | TheEditCatalogueName
  | SaksCatalogueName
  | HolidayCatalogueName
  | GiftSetsCatalogueName
  | ScentHoroscopeCatalogueName
  | ShopSampleCatalogueName
  | ShopTravelCatalogueName
  | ShopGiftsUnder50CatalogueName
  | HolidayShopCatalogueName

export type UseCatalogueIdProps = {
  catalogueName: CatalogueName
  skip?: boolean
}

const useCatalogueId = ({ catalogueName, skip = false }: UseCatalogueIdProps) => {
  const { flatStructure, isFetching } = useCatalogueStructure({ skip })

  const catalogueId = flatStructure?.[catalogueName]

  return {
    catalogueId,
    isFetching,
  }
}

export default useCatalogueId
